import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import resume from '../../assets/pdf/resume.pdf';
import { SizeMe } from 'react-sizeme';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export interface IResumeProps extends RouteComponentProps { }
export interface IResumeState { }

export class Resume extends React.Component<IResumeProps, IResumeState> {
  render() {
    return <div style={{ paddingLeft: 50, paddingRight: 50, paddingTop: 5  }}>
      <a href='./assets/resume.pdf' download>
        <Pdf />
      </a>
      <div style={{ height: '50px' }} />
    </div>;
  }
}

export function Pdf() {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages: number }: {
    numPages: any;
  }): void {
    setNumPages(numPages);
  }

  return (
    <section className="container max">
      <SizeMe
        monitorHeight
        refreshRate={128}
        refreshMode={"debounce"}
      >
        {({ size }) => (
          <Document className="pdf"
            file={resume}
            onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={1} width={size.width ? size.width : 1} />
          </Document>
        )}
      </SizeMe>
    </section>
  );
}