import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import { Emoji, Frames, Transition } from '../../components';

import Plane from '../../assets/svg/plane.svg';
import Github from '../../assets/svg/github.svg';
import LinkedIn from '../../assets/svg/linkedin.svg';

import IphoneScreens from '../../assets/image/iphone-screens.png';
import JsAndTs from '../../assets/image/js-ts.png';
import Birdy from '../../assets/image/birdy.png';
import Kings from '../../assets/image/kings.jpg';

export interface IHomeProps extends RouteComponentProps { }
export interface IHomeState { }
export type ContentType = undefined | null | JSX.Element | JSX.Element[]

export class Home extends React.Component<IHomeProps, IHomeState> {
  render() {
    return (
      <React.Fragment>
        <SiteHeader />
        <CardGrid title={'Work'} alignCard="left" cardColors={['blue', 'cloudy', 'pink']} content={{
          section1: <>
            <img className="image" alt="github" style={{ maxWidth: "100%" }} src={IphoneScreens} />
          </>,
          section2: <>
            <img className="image" alt="github" style={{ maxWidth: "40%" }} src={JsAndTs} />
          </>,
          section3: <>
            <p>
              <br />
              <a className="button big-button" href="https://apps.apple.com/au/app/melrose-park/id1481300057">{`> Melrose Park`}</a>
              <br />
              <a className="button big-button" href="https://apps.apple.com/au/app/the-woniora/id1528915417">{`> The Woniora`}</a>
              <br />
              <a className="button big-button" href="https://apps.apple.com/au/app/the-healey/id1502333913">{`> The Healey`}</a>
              <br />
              <a className="button big-button" href="https://www.medview.com.au/flow/medview-flow-mobile/">{`> Medview Flow`}</a>
            </p>
          </>,
        }} />
        <CardGrid title={'Play'} alignCard="right" cardColors={['grass', 'pink', 'moss']} content={{
          section1: <>
            <img className="image" alt="github" style={{ maxWidth: "35%", borderRadius: 10 }} src={Kings} />
          </>,
          section2: <p style={{ fontFamily: 'monaco', fontSize: 12, padding: 40, textAlign: 'justify' }}>{`“We all step in shit from time to time. We hit roadblocks, we fuck up, we get fucked, we get sick, we don’t get what we want, we cross thousands of “could have done better”s and “wish that wouldn’t have happened”s in life. Stepping in shit is inevitable, so let’s either see it as good luck, or figure out how to do it less often.”\n- Matthew McConaughey`}</p>,
          section3: <>
            <Transition />
          </>,
        }} />
        <CardGrid title={'Design'} alignCard="left" cardColors={['red', 'milk', 'gravel']} content={{
          section1: <>
            <Frames />
          </>,
          section2: <>
            <img className="image" alt="github" style={{ maxWidth: "45%" }} src={Birdy} />
          </>,
          section3: <p style={{ fontFamily: 'monaco', fontSize: 12, padding: 40, textAlign: 'justify' }}>{`“When you think that you are done, you're only 40% in to what your body's capable of doing. That's just the limits that we put on ourselves.”\n- David Goggins`}</p>,
        }} />
        <Qoute />
        <Contact />
        <Footer />
      </React.Fragment>
    );
  }
}

export function SiteHeader() {
  const blur = [
    <p className="text h1">Hola, Amigo <Emoji char='👋' props={{ style: { paddingLeft: 10 } }} /> </p>,
    <p className="text h2 paragraph">My name is James Huang. I am a developer, designer, tinkerer, and bonsai enthusiast <Emoji char='🌳' /></p>
  ];
  return (
    <section className="container max padding p-top p-bottom">
      <div className="home-header max">
        <div className="center col" style={{ flex: 1.5 }}>
          {blur.map((x, i) =>
            React.cloneElement(x, { key: i, style: { paddingBottom: blur.length - 1 === i ? 0 : 20 } }),
          )}
        </div>
        <div className="space col" />
        <div className="center col" style={{ flex: 1 }}>
        </div>
      </div>
    </section>
  );
}

export function CardGrid({
  title = 'Title',
  alignCard = 'left',
  cardColors = ['', '', ''],
  content = { section1: undefined, section2: undefined, section3: undefined } }:
  {
    title?: string,
    alignCard?: 'left' | 'right',
    cardColors: string[],
    content: {
      section1: ContentType,
      section2: ContentType,
      section3: ContentType
    }
  }) {
  const [cardHeaderRef, cardHeaderInView] = useInView({ triggerOnce: true });
  const [cardContainerRef, cardContainerInView] = useInView({ triggerOnce: true });
  const [cardHeaderSpring, setCardHeaderSpring] = useSpring(() => ({ from: { opacity: 0, transform: 'translate3d(0,0px,0)' } }))
  const [cardContainerSpring, setCardContainerSpring] = useSpring(() => ({
    boxShadow: '0 1px 2px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.07), 0 8px 16px rgba(0,0,0,0.07),0 16px 32px rgba(0,0,0,0.07), 0 32px 64px rgba(0,0,0,0.07)',
    borderRadius: 30,
    overflow: 'hidden',
    from: {
      opacity: 0,
      transform: "translate3d(0,0px,0)"
    }
  }))

  if (cardHeaderInView) {
    setCardHeaderSpring({ transform: 'translate3d(0,-40px,0)', opacity: 1, delay: 750 })
  }
  if (cardContainerInView) {
    setCardContainerSpring({ transform: 'translate3d(0,-40px,0)', opacity: 1, delay: 750 })
  }

  return (
    <section className="container max">
      <div className={`home-body-${alignCard} center`} style={{ paddingTop: 250 }}>
        <div className="row-1" />
        <div className="row-2">
          <div style={{ zIndex: 10, position: 'relative' }} ref={cardHeaderRef}>
            <animated.p className={`text h1 ${alignCard} card-header`} style={cardHeaderSpring}>{title}</animated.p>
          </div>
          <div ref={cardContainerRef}>
            <animated.div style={cardContainerSpring}>
              <div className={`padding p-base card ${cardColors[0]}`}>
                {content.section1}
              </div>
              <div className="card-row">
                <div className={`padding p-base card ${cardColors[1]}`}>
                  {content.section2}
                </div>
                <div className={`padding p-base card ${cardColors[2]}`}>
                  {content.section3}
                </div>
              </div>
            </animated.div>
          </div>
        </div>
        <div className="row-3"></div>
      </div>
    </section>
  );
}

export function Contact() {
  const blur = [
    <p className="text h1"><Emoji char='🐒' props={{ style: { paddingRight: 15 } }} />Contact Me</p>,
    <a href="https://www.linkedin.com/in/james-huang-463546126/" className="link">
      <p className="text h2 paragraph" style={{ paddingRight: 10, paddingTop: 4 }}>Linkedin</p>
      <img className="image" alt="linkedin" style={{ height: 26 }} src={LinkedIn} />
    </a>,
    <a href="https://github.com/james-huang-git" className="link">
      <p className="text h2 paragraph" style={{ paddingRight: 10, paddingTop: 4 }}>Github</p>
      <img className="image" alt="github" style={{ height: 26 }} src={Github} />
    </a>,
    <a href="mailto:james.jy.huang@hotmail.com" className="link">
      <p className="text h2 paragraph" style={{ paddingRight: 10, paddingTop: 4 }}>Email Me</p>
      <img className="image" alt="plane" style={{ height: 26 }} src={Plane} />
    </a>
  ];
  return (<section className="container max padding p-top p-bottom" style={{ paddingBottom: 250 }}>
    <div className="home-header max">
      <div className="space col" />
      <div className="center col" style={{ flex: 1.5 }}>
        {blur.map((x, i) =>
          React.cloneElement(x, { key: i, style: { textAlign: 'right', paddingBottom: blur.length - 1 === i ? 0 : 20, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' } }),
        )}
      </div>
    </div>
  </section>)
}

export function Qoute() {
  return (<section className="container max hawaii-section" style={{ marginTop: 180, marginBottom: 180, flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <a href="https://www.youtube.com/watch?v=EuER2Puym4I&ab_channel=BenjaminSmith" className="text h3 footer">“Alright alright alright."</a>
  </section>)
}

export function Footer() {
  return (<section className="container max padding p-bottom">
    <p className="text h3 footer">This website was handmade with</p>
    <p className="text h3 footer"> <Emoji char='❤️' /> by James Huang</p>
  </section>)
}