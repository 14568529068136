import React from 'react';
import { NavLink, LinkProps } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import { useScrollPosition } from '../common/scrollPosition';
import BaseLogo from '../assets/svg/logo.svg';
import { INavContext, NavContext } from '../context';

export type Link = { [key: string]: LinkProps & { label: string } };

const linkMap: Link = {
  home: {
    to: '/',
    label: 'Home',
  },
  resume: {
    to: '/resume',
    label: 'Resumé',
  },
};


export const NavLinks = ({ links }: { links: Link }) => {
  return (
    <nav className="navlinks">
      {Object.entries(links).map(([key, value], index) => {
        const last = Object.keys(links).length - 1 === index;
        return (
          <NavLink
            activeClassName="nav-link-active"
            exact
            className="nav-link text h3 nav"
            style={{ paddingRight: last ? 0 : 35 }}
            key={key}
            to={value.to}
          >
            {value.label}
          </NavLink>
        );
      })}
    </nav>
  );
}

export const NavBar = ({ nav, navStyle }: { nav: INavContext, navStyle?: React.CSSProperties | undefined }) => {
  return (
    <nav style={navStyle} className="navbar disable-select" onClick={nav.toggleNav}>
      <i className="material-icons">{nav.icon}</i>
    </nav>
  )
}

export const NavMenu = ({ links, nav }: { links: Link, nav: INavContext }) => {
  if (nav.show) {
    return (
      <React.Fragment>
        <div className="nav-container container max padding p-top p-bottom" style={{ zIndex: 30}}>
          <section className="padding p-top p-bottom">
            <img className="image" style={{ 'maxHeight': 40 }} alt="logo" src={BaseLogo} />
            <nav className="navmenu-bar disable-select" onClick={nav.toggleNav}>
              <i className="material-icons">{nav.icon}</i>
            </nav>
          </section>
        </div>
        <div className="navmenu">
          <nav className="navlinks" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            {Object.entries(links).map(([key, value], index) => {
              const last = Object.keys(links).length - 1 === index;
              return (
                <NavLink
                  activeClassName="nav-link-active"
                  exact
                  className="nav-link text h3 nav"
                  style={{ marginBottom: last ? 0 : 35 }}
                  key={key}
                  to={value.to}
                  onClick={nav.toggleNav}
                >
                  {value.label}
                </NavLink>
              );
            })}
          </nav>
        </div>
      </React.Fragment>
    )
  } else {
    return null;
  }
}

export const Navigator = () => {
  const [springHeader, setSpringHeader] = useSpring(() => ({
    opacity: 1,
    backgroundColor: '#FEFBF5'
  }))
  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y > -100) {
      setSpringHeader({ opacity: 1, backgroundColor: '#FEFBF5' })
    } else if (currPos.y > prevPos.y && currPos.y - prevPos.y > 50) {
      setSpringHeader({ opacity: 1 })
    } else if (currPos.y < prevPos.y) {
      setSpringHeader({ opacity: 0, backgroundColor: "#FEFBF5" })
    }
  })
  return (
    <NavContext.Consumer>
      {(nav) => (
        <React.Fragment>
          {!nav.show ? <animated.div style={springHeader} className="nav-container container max padding p-top p-bottom">
            <section className="padding p-top p-bottom">
              <img className="image" style={{ 'maxHeight': 40 }} alt="logo" src={BaseLogo} />
              <NavLinks links={linkMap} />
              <NavBar nav={nav} />
            </section>
          </animated.div> : null}
          <NavMenu links={linkMap} nav={nav} />
        </React.Fragment>
      )}
    </NavContext.Consumer>
  )
}
