import React from 'react';

export interface INavContext {
  show: boolean,
  icon: string,
  toggleNav: () => void
}

export const NavContext = React.createContext({
  show: false,
  icon: 'menu',
  toggleNav: () => {}
})