import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Home, Error, Resume } from './pages/index';
import { Navigator } from '../components';
import { Types } from '../common';
import { INavContext, NavContext } from '../context';

export type RouteMap = Types.RouteMap;

const routeMap: Types.RouteMap = {
  home: {
    exact: true,
    path: '/',
    component: Home
  },
  resume: {
    exact: true,
    path: '/resume',
    component: Resume
  },
  error: {
    exact: false,
    path: '/404',
    component: Error
  }
}

export interface IAppProps { };
export interface IAppState extends Contexts { };
export type Contexts = INavContext;

export class App extends React.Component<IAppProps, IAppState> {
  public toggleNav: () => void = () => { }
  constructor(props: IAppProps) {
    super(props);
    this.toggleNav = () => {
      this.setState(state => {
        let i = 'menu';
        if (state.show === true) {
          document.body.className = "show-scroll";
          i = 'menu'
        } else {
          document.body.className = "hide-scroll";
          i = 'clear'
        }
        return ({
          show: !state.show,
          icon: i
        })
      })
    }
    this.state = {
      show: false,
      icon: 'menu',
      toggleNav: this.toggleNav
    }
  }
  createRoutes(routes: RouteMap): JSX.Element[] {
    return Object.entries(routes).map(([key, value]) => {
      return <Route key={key} {...value} />
    })
  }
  render() {
    return (
      <React.Fragment>
        <NavContext.Provider value={this.state}>
          <BrowserRouter>
            <Navigator />
            <Switch>
              {this.createRoutes(routeMap)}
              <Redirect to="/404" />
            </Switch>
          </BrowserRouter>
        </NavContext.Provider>
      </React.Fragment>
    );
  }
}

export default App;
